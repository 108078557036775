<template>
  <table>
    <tr>
      <td>
        <ion-avatar>
          <img
            :src="
              (cc_note.created_by && cc_note.created_by.profile_photo_url)
                ? cc_note.created_by.profile_photo_url
                : 'https://borym.s3.amazonaws.com/media/soft_green_light.png'
            "
          />
        </ion-avatar>
      </td>
      <td style="padding-left: 8px">
        <span class="h6">{{ (cc_note.created_by) ? cc_note.created_by.fullname : '' }}</span><br />
        {{ formatTimeAndDate(cc_note.datetime) }}
      </td>
    </tr>
  </table>
  <p class="ion-padding-bottom" style="white-space: pre-line">{{ cc_note.notes }}</p>
</template>

<script>
import { IonAvatar } from "@ionic/vue";
import { formatTimeAndDate } from "@/util/helpers"

export default {
  props: {
    cc_note: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonAvatar,
  },

  data(){
    return{
      formatTimeAndDate
    }
  }
};
</script>