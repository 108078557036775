<template>
  <base-layout-two :page-title="user.fullname" :page-default-back-link="`/users/${$route.params.id}`"
    content-class="background-white">
    <section class="padding-x-16">
      <h1>Boram Anywhere Notes</h1>
      <ion-button class="regular-button" expand="block"
        :router-link="`/users/${$route.params.id}/continued_care/cc_notes/create`">
        New Boram Anywhere Note
      </ion-button>
    </section>

    <section class="padding-x-16 padding-top-16">
      <ion-list>
        <continued-care-note-list-item v-for="cc_note in cc_notes" :key="cc_note.id" :cc_note="cc_note">
        </continued-care-note-list-item>
      </ion-list>
    </section>
  </base-layout-two>
</template>

<script>
import { IonButton, IonList } from "@ionic/vue";
import ContinuedCareNoteListItem from "@/components/users/continued_care/ContinuedCareNoteListItem.vue";

export default {
  components: {
    IonButton,
    IonList,
    ContinuedCareNoteListItem,
  },
  data() {
    return {
      user: {},
      cc_notes: [],
    };
  },
  ionViewWillEnter() {
    this.fetchUser();
    this.fetchCcNotes();
  },
  methods: {
    async fetchUser() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/${this.$route.params.id}`)
        .then((response) => {
          this.user = response.data.user;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async fetchCcNotes() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/activities?user_id=${this.$route.params.id}&type=cc_note`
        )
        .then((response) => {
          this.cc_notes = response.data.activities.sort((a, b) => {
            return new Date(b.datetime) - new Date(a.datetime);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>